// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {reportError} from '@github-ui/failbot'
import {sendEvent} from '@github-ui/hydro-analytics'

const GENERIC_CLICK_EVENT_NAME = 'analytics.click'
const GENERIC_VISIBLE_EVENT_NAME = 'analytics.visible'
const ANALYTICS_ATTR = 'data-analytics-event'
const ANALYTICS_VISIBLE_ATTR = 'data-analytics-visible'
const CLICK_SELECTORS = `a:not([${ANALYTICS_ATTR}]), button:not([${ANALYTICS_ATTR}]), [${ANALYTICS_ATTR}]`
const VISIBLE_SELECTORS = `[${ANALYTICS_VISIBLE_ATTR}]`

on('click', CLICK_SELECTORS, event => {
  if (!event || !event.currentTarget) {
    return
  }

  try {
    sendEvent(GENERIC_CLICK_EVENT_NAME, getClickContext(event.currentTarget))
    // Fire only once for details elements, that are toggable
    if (isTag(event.currentTarget, 'details')) {
      event.currentTarget.removeAttribute(ANALYTICS_ATTR)
    }
  } catch (e) {
    reportError(e)
  }
})

function getClickContext(currentTarget: EventTarget & Element) {
  return {
    ...(isTag(currentTarget, 'a') && getLinkAttributes(currentTarget as HTMLAnchorElement)),
    ...(isTag(currentTarget, 'button') && getButtonAttributes(currentTarget as HTMLButtonElement)),
    ...getRefLoc(currentTarget as HTMLElement),
    ...parseAnalyticsAttr(currentTarget.getAttribute(ANALYTICS_ATTR)),
  }
}

function getVisibleContext(currentTarget: EventTarget & Element) {
  return {
    ...(isTag(currentTarget, 'a') && getLinkAttributes(currentTarget as HTMLAnchorElement)),
    ...(isTag(currentTarget, 'button') && getButtonAttributes(currentTarget as HTMLButtonElement)),
    ...getRefLoc(currentTarget as HTMLElement),
    ...parseAnalyticsAttr(currentTarget.getAttribute(ANALYTICS_VISIBLE_ATTR)),
  }
}

function isTag(element: Element, tagName: string) {
  return element.tagName.toLowerCase() === tagName
}

function getLinkAttributes(linkEl: HTMLAnchorElement) {
  return {
    /* eslint-disable-next-line github/no-innerText */
    text: linkEl.innerText || linkEl.getAttribute('aria-label') || '',
    target: linkEl.href,
  }
}

function getButtonAttributes(buttonEl: HTMLButtonElement) {
  const form = buttonEl.closest('form')

  return {
    /* eslint-disable-next-line github/no-innerText */
    text: buttonEl.innerText || buttonEl.getAttribute('aria-label') || '',
    role: buttonEl.getAttribute('type') || buttonEl.getAttribute('role') || 'button',
    ...(buttonEl.value && {
      value: buttonEl.value,
    }),
    ...(form && {
      formAction: form.getAttribute('action') || '',
    }),
  }
}

function getRefLoc(elem: HTMLElement) {
  const {top: relTop, left: relLeft} = elem.getBoundingClientRect()

  const body = document.body
  const html = document.documentElement

  const contentHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  )

  const contentWidth = Math.max(
    body.scrollWidth,
    body.offsetWidth,
    html.clientWidth,
    html.scrollWidth,
    html.offsetWidth,
  )

  const top = ((relTop + window.pageYOffset) / contentHeight).toFixed(3)
  const left = ((relLeft + window.pageXOffset) / contentWidth).toFixed(3)

  return {
    ref_loc: JSON.stringify({
      top,
      left,
    }),
  }
}

function parseAnalyticsAttr(dataAnalyticsClick: string | null) {
  if (!dataAnalyticsClick) {
    return {}
  }

  const analyticsEventAttributes = JSON.parse(dataAnalyticsClick)
  const {label} = analyticsEventAttributes

  return {
    ...parseLabels(label),
    ...analyticsEventAttributes,
  }
}

function parseLabels(labelString: string) {
  if (!labelString) {
    return {}
  }

  const labelFields: LabelFields = {}

  const labels = labelString.split(';').map(label => label.trim())
  for (const label of labels) {
    const [key, val] = label.split(':')
    if (key) {
      labelFields[key.trim()] = val?.trim() || key.trim()
    }
  }

  return labelFields
}

type LabelFields = Record<string, string>

const defaultObserver = new IntersectionObserver(sendVisibilityEvent, {
  rootMargin: `0% 0% -30% 0%`,
  threshold: 0,
})

observe(VISIBLE_SELECTORS, element => {
  defaultObserver.observe(element)
})

function sendVisibilityEvent(entries: IntersectionObserverEntry[]) {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      try {
        sendEvent(GENERIC_VISIBLE_EVENT_NAME, getVisibleContext(entry.target))
        defaultObserver.unobserve(entry.target)
      } catch (e) {
        reportError(e)
      }
    }
  }
}
