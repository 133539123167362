// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

export const BUILDINCLASS = 'build-in-animate'
const BUILDINCLASSREDUCED = 'build-in-reduced'
export const DEFAULTMARGINBOTTOM = 30
const DEFAULTMARGINBOTTOMVIDEO = 20
export const DEFAULTMARGINTOP = 0
export const DEFAULTTHRESHOLD = 0

observe('.js-build-in-trigger[data-build-in-stagger], .js-build-in-group[data-build-in-stagger]', element => {
  const stagger = parseInt(element.getAttribute('data-build-in-stagger')!)
  const items = element.querySelectorAll<HTMLElement>('.js-build-in-item')

  for (let i = 0; i < items.length; i++) items[i]!.style.transitionDelay = `${i * stagger}ms`
})

const defaultObserver = new IntersectionObserver(toggleAnimationClasses, {
  rootMargin: `-${DEFAULTMARGINTOP}% 0% -${DEFAULTMARGINBOTTOM}% 0%`,
  threshold: DEFAULTTHRESHOLD,
})

observe('.js-build-in-item[data-build-delay]', element => {
  const delay = Number(element.getAttribute('data-build-delay') || 0)
  if (!(element instanceof HTMLElement)) return
  element.style.transitionDelay = `${delay}ms`
})

observe('.js-build-in, .js-build-in-trigger', element => {
  // Listen to prefers reduced motion, and cancel all animation if true
  if (shouldReduceMotion(element)) {
    cancelAnimationsForElement(element)
    return
  }

  const options = animationOptions(element)
  if (options.isDefault) return defaultObserver.observe(element)

  const customObserver = new IntersectionObserver(toggleAnimationClasses, {
    rootMargin: `-${options.marginTop}% 0% -${options.marginBottom}% 0%`,
    threshold: options.threshold,
  })
  customObserver.observe(element)
})

function toggleAnimationClasses(entries: IntersectionObserverEntry[]) {
  for (const entry of entries) {
    entry.target.classList.toggle(BUILDINCLASS, entry.isIntersecting)
    if (!entry.target.classList.contains('js-build-in-trigger')) continue

    for (const item of entry.target.querySelectorAll('.js-build-in-item')) {
      item.classList.toggle(BUILDINCLASS, entry.isIntersecting)
    }
  }
}

export function shouldReduceMotion(element: Element) {
  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
  // If the animation serves a non-decorative purpose, don't cancel it
  const nonDecorative = element.getAttribute('data-build-non-decorative') || 'false'
  // Reduce if it reduce motion is turned on, and if the animation is decorative
  return mediaQuery.matches && nonDecorative === 'false'
}

export function cancelAnimationsForElement(element: Element) {
  element.classList.add(BUILDINCLASSREDUCED)
  element.classList.add(BUILDINCLASS)
  for (const item of element.querySelectorAll('.js-build-in-item')) {
    item.classList.add(BUILDINCLASSREDUCED)
    item.classList.add(BUILDINCLASS)
  }
}

export function animationOptions(element: Element) {
  const marginBottom = Number(element.getAttribute('data-build-margin-bottom') || DEFAULTMARGINBOTTOM)
  const marginTop = Number(element.getAttribute('data-build-margin-top') || DEFAULTMARGINTOP)
  const threshold = Number(DEFAULTTHRESHOLD)

  return {
    marginBottom,
    marginTop,
    threshold,
    isDefault: marginBottom === DEFAULTMARGINBOTTOM && marginTop === DEFAULTMARGINTOP && threshold === DEFAULTTHRESHOLD,
  }
}

function getPauseToggleLabel(element: HTMLElement) {
  const playLabel = element.getAttribute('data-play-aria-label')
  const pauseLabel = element.getAttribute('data-pause-aria-label')
  if (!playLabel || !pauseLabel) return

  const ariaLabel = element.getAttribute('aria-label')
  const playAriaLabel = ariaLabel ? `${ariaLabel} ${playLabel}` : playLabel
  const pauseAriaLabel = ariaLabel ? `${ariaLabel} ${pauseLabel}` : pauseLabel

  return {playAriaLabel, pauseAriaLabel}
}

observe('.js-viewport-aware-video', {
  constructor: HTMLMediaElement,
  add(video) {
    const bindPlayPauseLabels = (videoElement: HTMLMediaElement) => {
      const labels = getPauseToggleLabel(videoElement)
      if (!labels) return
      const {playAriaLabel, pauseAriaLabel} = labels
      videoElement.setAttribute('aria-label', videoElement.paused ? playAriaLabel : pauseAriaLabel)

      videoElement.addEventListener('play', () => {
        videoElement.setAttribute('aria-label', pauseAriaLabel)
      })

      videoElement.addEventListener('pause', () => {
        videoElement.setAttribute('aria-label', playAriaLabel)
      })
    }
    bindPlayPauseLabels(video)

    const togglePlay = (videoElement: HTMLMediaElement) => {
      if (videoElement.paused) {
        // eslint-disable-next-line github/no-then
        videoElement.play().catch(() => {})
      } else {
        videoElement.pause()
      }
    }

    video.addEventListener('play', () => {
      video.classList.remove('looping-paused-mktg')
    })

    video.addEventListener('pause', () => {
      video.classList.add('looping-paused-mktg')
    })

    video.addEventListener('click', () => {
      togglePlay(video)
    })

    video.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.code !== 'Space') return
      e.preventDefault()
      togglePlay(video)
    })

    if (shouldReduceMotion(video)) {
      video.pause()
      video.classList.add('looping-paused-mktg')
      return
    }

    const threshold = video.getAttribute('data-threshold') || DEFAULTTHRESHOLD
    const marginBottom = video.getAttribute('data-build-margin-bottom') || DEFAULTMARGINBOTTOMVIDEO
    const observer = new IntersectionObserver(
      entries => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            // eslint-disable-next-line github/no-then
            video.play().catch(() => {})
          } else {
            video.pause()
          }
        }
      },
      {
        rootMargin: `-${DEFAULTMARGINTOP}% 0% -${marginBottom}% 0%`,
        threshold: Number(threshold),
      },
    )

    observer.observe(video)
  },
})

observe('.js-animation-pause-toggle', element => {
  const labels = getPauseToggleLabel(element as HTMLElement)
  if (labels) {
    element.setAttribute(
      'aria-label',
      element.getAttribute('aria-pressed') === 'false' ? labels.pauseAriaLabel : labels.playAriaLabel,
    )
  }

  element.addEventListener('click', () => {
    const targetId = element.getAttribute('data-target-id')
    if (!targetId) return
    const target = document.getElementById(targetId)
    if (!target) return

    const state = target.getAttribute('data-animation-paused') || 'false'
    const paused = state === 'true'
    target.setAttribute('data-animation-paused', paused ? 'false' : 'true')
    element.setAttribute('aria-pressed', paused ? 'false' : 'true')

    if (!labels) return
    element.setAttribute('aria-label', paused ? labels.pauseAriaLabel : labels.playAriaLabel)
  })
})
