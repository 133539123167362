// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

// Like scrollIntoView() but better because:
// 1. It doesn't change scroll position if element already fits into viewport.
// 2. It supports the option padding value.
function adjustViewport(element: HTMLElement, padding = 0) {
  const position = element.getBoundingClientRect()
  const scrollAdjustUp = position.top - padding
  const scrollAdjustDown = position.bottom - window.innerHeight + padding

  if (scrollAdjustUp < 0) {
    window.scrollBy(0, scrollAdjustUp)
  } else if (scrollAdjustDown > 0) {
    window.scrollBy(0, scrollAdjustDown)
  }
}

on('click', '.js-video-play-dialog', event => {
  const button = event.currentTarget as HTMLButtonElement
  const dialogID = button.getAttribute('data-show-dialog-id')
  if (!dialogID) return
  const dialog = document.getElementById(dialogID)
  if (!dialog) return
  const video = dialog.querySelector<HTMLMediaElement | HTMLIFrameElement>('.js-video')!
  if (video instanceof HTMLIFrameElement) video.src = video.getAttribute('data-src') || ''
  if (video instanceof HTMLMediaElement) video.play()
})

observe('.js-video-dialog', dialog => {
  dialog.addEventListener('close', () => {
    const video = dialog.querySelector<HTMLMediaElement | HTMLIFrameElement>('.js-video')!
    if (video instanceof HTMLIFrameElement) video.removeAttribute('src')
    if (video instanceof HTMLMediaElement) video.pause()
  })
})

on('click', '.js-video-play, .js-video-close', function (event) {
  const playTarget = event.currentTarget
  const container = playTarget.closest<HTMLElement>('.js-video-container')!
  const video = container.querySelector<HTMLMediaElement | HTMLIFrameElement>('.js-video')!

  if (container.tagName.toLowerCase() === 'details') {
    container.addEventListener('details-dialog-close', function () {
      if (video instanceof HTMLIFrameElement) video.removeAttribute('src')
      if (video instanceof HTMLMediaElement) video.pause()

      window.setTimeout(function () {
        container.classList.remove('is-expanded')
      }, 10)
    })
  }

  if (!container.classList.contains('is-expanded')) {
    if (video instanceof HTMLIFrameElement) video.src = video.getAttribute('data-src') || ''
    if (video instanceof HTMLMediaElement) video.play()
    container.classList.add('is-expanded')
  } else {
    if (video instanceof HTMLIFrameElement) video.removeAttribute('src')
    container.classList.remove('is-expanded')
    if (video instanceof HTMLMediaElement) video.pause()
  }

  adjustViewport(video, 20)
})
