// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

observe('.js-dialog-close-on-focusout', dialog => {
  dialog.addEventListener('focusout', event => {
    const relatedTarget = (event as FocusEvent).relatedTarget as HTMLElement
    if (!relatedTarget) return
    if (!dialog.contains(relatedTarget)) (dialog as HTMLDialogElement).open = false
  })
})
